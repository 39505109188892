import React from 'react'

class NotFoundPage extends React.Component {

  componentDidMount() {
    window.location = '/'
  }

  render() {

    return (
      <h2>404</h2>
    )
  }
}

export default NotFoundPage
